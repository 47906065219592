/* ------------- */
/*    General    */
/* ------------- */


.btn {
  font-weight: normal;
  position: relative;
  font-size: 16px;
  line-height: 38px;
  padding: 0 20px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  text-align: center;
  transition: all .3s;
  &:focus,
  &:active,
  &:active:focus,
  &.active:focus {
    outline: none !important;
    box-shadow: none !important; }
  &.btn-default {
    color: #FFFFFF;
    background-color: $text;
    border-color: $text;
    &:hover,
    &:not(:disabled):not(.disabled):active {
      background-color: darken($text, 10%);
      border-color: darken($text, 10%); } }
  &.btn-sm {
    font-size: 14px;
    line-height: 28px;
    padding: 0 15px; }
  &.btn-xs {
    font-size: 12px;
    line-height: 23px;
    padding: 0 15px; } }

.container-small {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
    width: 95%; } }


.title-section {
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  color: $primary;
  text-align: center;
  margin-bottom: 30px;
  h2 {
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    &:last-child {
      margin-bottom: 0; } }
  .big {
    display: block;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: $text; }
  @include mobile {
    margin-bottom: 15px; } }




.title-block {
  font-size: 15px;
  font-height: 24px;
  font-weight: 600;
  color: $primary;
  margin-bottom: 10px; }

.lead {
  text-align: center;
  margin-bottom: 60px;
  p {
    font-size: 21px;
    line-height: 36px;
    font-weight: normal;
    color: $text; } }

.img-block {
  width: 100%;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    min-height: 400px;
    object-fit: cover; } }

.slider-xs {
  @include mobile {
    margin-left: 0;
    margin-right: 0;
    .col-sm-4 {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0; } } }

.modal-backdrop.in {
  opacity: 0.75; }
.modal {
  .modal-vac {
    min-height: calc(100% - (1.75rem * 2));
    display: flex;
    align-items: center;
    position: relative; }
  .modal-close {
    position: absolute;
    top: 40px;
    right: 40px;
    line-height: 1;
    cursor: pointer;
    opacity: 1;
    transition: opacity .5s;
    z-index: 2;
    &:hover {
      opacity: .5; } }
  .modal-dialog {
    width: 1290px;
    .modal-content {
      box-shadow: none;
      border: 0;
      border-radius: 20px;
      padding: 60px; } }
  @include maxtablet {
    .modal-dialog {
      width: 95%;
      .modal-content {
        padding: 20px; } }
    .modal-close {
      top: 20px;
      right: 20px; }
    .posts {
      margin-bottom: 40px;
      .post {
        margin-bottom: 10px; } } } }


.parallax-window {
  min-height: 60vh;
  background: transparent; }


.text-center {
  text-align: center; }
.text-left {
  text-align: left; }
.text-right {
  text-align: right; }
.text-justify {
  text-align: justify; }

.pt-120 {
  padding-top: 120px;
  @include mobile {
    padding-top: 60px; } }
.pb-120 {
  padding-bottom: 120px;
  @include mobile {
    padding-bottom: 60px; } }
.py-120 {
  padding-top: 120px;
  padding-bottom: 120px;
  @include mobile {
    padding-top: 60px;
    padding-bottom: 60px; } }

.pt-60 {
  padding-top: 60px;
  @include mobile {
    padding-top: 30px; } }
.pb-60 {
  padding-bottom: 60px;
  @include mobile {
    padding-bottom: 30px; } }
.py-60 {
  padding-top: 60px;
  padding-bottom: 60px;
  @include mobile {
    padding-top: 30px;
    padding-bottom: 30px; } }


.mt-120 {
  margin-top: 120px;
  @include mobile {
    margin-top: 60px; } }
.mb-120 {
  margin-bottom: 120px;
  @include mobile {
    margin-bottom: 60px; } }
.my-120 {
  margin-top: 120px;
  margin-bottom: 120px;
  @include mobile {
    margin-top: 60px;
    margin-bottom: 60px; } }

.mt-60 {
  margin-top: 60px;
  @include mobile {
    margin-top: 30px; } }
.mb-60 {
  margin-bottom: 60px;
  @include mobile {
    margin-bottom: 30px; } }
.my-60 {
  margin-top: 60px;
  margin-bottom: 60px;
  @include mobile {
    margin-top: 30px;
    margin-bottom: 30px; } }
