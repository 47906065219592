@import 'cores/variable.sass';
@import 'cores/mixin';

/* ------------------------------ */
/* CORE */
/* ------------------------------ */
@import 'cores/fonts';
@import 'cores/base';

/* ------------------------------ */
/* COMPONENTS */
/* ------------------------------ */
@import 'components/custom-plugins';
@import 'components/animation';
@import 'components/general';
@import 'components/header';
@import 'components/footer';
@import 'components/form';
@import 'components/content';
