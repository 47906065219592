@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0); }

  40% {
    -moz-transform: translateY(-10px);
    transform: translateY(-10px); }

  60% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px); } }


@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }

  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }


@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0); }

  40% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }

  60% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@keyframes appear {
  0% {
    opacity: 0; }
  50% {
    opacity: .5; }
  100% {
    opacity: 1; } }

@keyframes appear-up {
  0% {
    opacity: 0;
    transform: translate(0, 20px); }
  50% {
    opacity: .5;
    transform: translate(0, 10px); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes appear-right {
  0% {
    opacity: 0;
    transform: translate(-20px, 0); }
  50% {
    opacity: .5;
    transform: translate(-10px, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes blur-in {
  0% {
    transform: translateZ(0);
    filter: blur(0.5rem);
    opacity: 0; }
  100% {
    transform: translateZ(0);
    filter: blur(0);
    opacity: 1; } }

@keyframes blur-in-right {
  0% {
    opacity: 0;
    transform: translate3d(-2%, 0, 0); }
  50% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes blur-out-right {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  99% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(2%, 0, 0); } }

@keyframes blur-in-left {
  0% {
    opacity: 0;
    transform: translate3d(2%, 0, 0); }
  50% {
    opacity: 1; }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes blur-out-left {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  99% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translate3d(-2%, 0, 0); } }


@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 }    // opacity: 1
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
 } }    // opacity: 0

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 }    // opacity: 1
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
 } }    // opacity: 0

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
 }    // opacity: 0
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 } }    // opacity: 1

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
 }    // opacity: 0
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
 } }    // opacity: 1

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp; }


@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  to {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.02); } }
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  to {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.02); } }
.fadeOut {
  animation-name: fadeOut; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.02); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); } }
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(1.02); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); } }
.fadeIn {
  animation-name: fadeIn;
 }  // animation: fadeIn 6s linear infinite


@-webkit-keyframes bgUp {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1; }
  80% {
    transform: translate3d(0, -20px, 0) scale(1.025);
    opacity: 1; }
  100% {
    transform: translate3d(0, -25px, 0) scale(1.05);
    opacity: 0; } }
@keyframes bgUp {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1; }
  80% {
    transform: translate3d(0, -20px, 0) scale(1.025);
    opacity: 1; }
  100% {
    transform: translate3d(0, -25px, 0) scale(1.05);
    opacity: 0; } }
