/* --------------------------- */
/* OWL CAROUSEL */
/* --------------------------- */

.owl-carousel {
  position: relative;
  * {
    outline: none !important; }
  .owl-stage-outer {
    .owl-stage {
      .owl-item {
        position: relative; } } }

  .owl-nav {
    position: absolute;
    top: calc(50% - 2.5rem);
    width: 100%;
    button.owl-prev,
    button.owl-next {
      position: absolute;
      padding: 0 8px !important;
      line-height: 2.5rem;
      border-radius: 0;
      font-size: 1rem;
      color: $primary;
      background-color: rgba(255,255,255,0.6); }
    .owl-prev {
      left: 0; }
    .owl-next {
      right: 0; } }

  .owl-dots {
    text-align: center;
    position: relative;
    padding: 10px 0;
    width: 100%;
    bottom: 0;
    line-height: 10px;
    outline: none !important;
    .owl-dot {
      display: inline-block;
      margin: 0 10px;
      span {
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: $border; }
      &.active {
        span {
          background-color: $primary; } } } } }
