/* ------------------------------ */
/* FOOTER */
/* ------------------------------ */

footer {
  background: #282B32;
  padding: 40px 0 50px;
  border-bottom: 10px solid $primary;
  color: #FFFFFF;

  .foot-top,
  .foot-bot {
    @include clearfix(); }

  .block-left {
    float: left; }
  .block-right {
    float: right;
    @include mobile {
      float: left; } }


  .contanct-info {
    list-style: none;
    margin-bottom: 40px;
    &> li {
      display: inline-block;
      margin-right: 60px;
      &:last-child {
        margin-right: 0; } }
    span, a {
      display: inline-block;
      color: #FFFFFF;
      transition: all .5s; }
    a:hover {
      text-decoration: underline; }
    span {
      margin-right: 20px; } }


  .social-media {
    @include clearfix();
    margin-bottom: 60px;
    &> li {
      display: block;
      float: left;
      margin-right: -5px;
      border-radius: 50%;
      background: #DCDCDC;
      overflow: hidden;
      transition: background .5s;
      &:nth-child(2) {
        background: #F1F1F1; }
      &:nth-child(3) {
        background: #FAFAFA; }
      a {
        color: #57606F;
        display: block;
        width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        transition: all .5s; }
      &:hover {
        background: $primary;
        a {
          color: #FFFFFF; } } } }



  .foot-bot {
    a, span {
      display: inline-block;
      margin: 0 10px;
      color: #FFFFFF;
      transition: color .5s;
      &:first-child {
        margin-left: 0; }
      &:last-child {
        margin-right: 0; } }

    a:hover {
      text-decoration: underline; } } }

#toTop:hover {
  color: $primary;
  text-decoration: none; }
#toTop {
  @include mobile {
    margin-top: 30px; } }
