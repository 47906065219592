@mixin responsive($max:0,$min: 0) {
  @if $min == 0 {
    @media(max-width: $max) {
      @content; } }
  @else if $max == 0 {
    @media(min-width: $min) {
      @content; } }
  @else {
    @media(max-width: $max) and  (min-width: $min) {
      @content; } } }
@mixin mobile() {
  @include responsive(767px) {
    @content; } }
@mixin maxtablet() {
  @include responsive(991px) {
    @content; } }
@mixin mintablet() {
  @include responsive(0,768px) {
    @content; } }
@mixin minltablet() {
  @include responsive(0,992px) {
    @content; } }
@mixin tablet() {
  @include responsive(992px,768px) {
    @content; } }
@mixin ltablet() {
  @include responsive(1200px,992px) {
    @content; } }
@mixin sdekstop() {
  @include responsive(1200px,1024px) {
    @content; } }
@mixin large() {
  @include responsive(0,1200px) {
    @content; } }

@mixin clearfix() {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

@mixin abspos($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left; }

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@mixin pseudo($content: '', $display: block, $pos: absolute) {
  content: $content;
  display: $display;
  position: $pos; }

@mixin arrow($direction, $color, $size) {
  display: block;
  height: 0;
  width: 0;
  @if $direction == 'top' {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color; }
  @else if $direction == 'right' {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color; }
  @else if $direction == 'bottom' {
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent; }
  @else if $direction == 'left' {
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent; }
  @else if $direction == 'top-left' {
    border-top: $size solid $color;
    border-right: $size solid transparent; }
  @else if $direction == 'top-right' {
    border-top: $size solid $color;
    border-left: $size solid transparent; }
  @else if $direction == 'bottom-left' {
    border-bottom: $size solid $color;
    border-right: $size solid transparent; }
  @else if $direction == 'bottom-right' {
    border-bottom: $size solid $color;
    border-left: $size solid transparent; } }


@mixin fontGenerator($name,$weight,$family) {
  @for $i from 1 through length($family) {
    $src: #{"../fonts/"}#{nth($family, $i)};
    @font-face {
      font-family : $name;
      src         : local($name);
      src         : url("#{$src}.eot");
      src         : url("#{$src}.svg") format("svg"), url("#{$src}.eot#iefix") format("embedded-opentype"), url("#{$src}.ttf") format("truetype"), url("#{$src}.woff") format("woff");
      font-style  : normal;
      font-weight : nth($weight, $i); } } }


@mixin bggradient() {
  background: rgba(255,255,255,1);
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(100%, rgba(242,242,242,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2', GradientType=0 ); }
