/* ------------------------------ */
/* BASE */
/* ------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

hr {
  border: 0;
  border-top: 1px solid $border; }

.row.no-gutter {
  [class*="col-"] {
    padding: 0; } }

.row.row-20 {
  margin-left: -10px;
  margin-right: -10px;
  [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px; } }

.row.row-flex {
  display: flex;
  flex-wrap: wrap;
  .col-xs-6 {
    width: calc(50% - 1px); }
  @include responsive(0,1300px) {
    .col-md-4 {
      flex: 0 0 33.1%;
      max-width: 33.1%;
      width: 33.1%; } }
  @media (min-width: 768px) {
    .col-sm-6 {
      flex: 0 0 calc(50% - 1px);
      max-width: calc(50% - 1px);
      width: calc(50% - 1px); } }
  @include responsive(0,992px) {
    .col-md-4 {
      flex: 0 0 33.1%;
      max-width: 33.1%;
      width: 33.1%; } }
  @include responsive(0,760px) {
    .col-sm-4 {
      flex: 0 0 33.1%;
      max-width: 33.1%;
      width: 33.1%; } }


  @include mobile {
    [class*="col-"]:not([class*="col-xs"]) {
      width: 100%; }
    .col-xs-6 {
      width: calc(50% - 1px); } } }

/* ------------------------------ */
/* Typography */
/* ------------------------------ */

a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $link;
  text-decoration: none;
  opacity: 1;
  &:focus {
    text-decoration: none;
    color: $link;
    outline: none; }
  &:hover {
    color: lighten($link, 20%);
    text-decoration: none; } }

p {
  margin: 0 0 30px;
  padding: 0;
  strong, b {
    font-weight: 800; }
  em, i {
    font-style: italic; }
  a:hover {
    text-decoration: underline; }
  &:last-child {
    margin-bottom: 0; } }


h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px;
  font-weight: 800; }

h1 {
  font-size: 36px;
  line-height: 46px;
  @include mobile {
    font-size: 32px;
    line-height: 42px; } }

h2 {
  font-size: 21px;
  line-height: 30px;
  @include mobile {
    font-size: 18px;
    line-height: 26px; } }
h3 {
  font-size: 16px;
  line-height: 24px; }
h4 {
  font-size: 14px;
  line-height: 20px; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }
.list-inline > li {
  padding: 0; }

.text-center {
  text-align: center; }
.text-right {
  text-align: right; }
.text-left {
  text-align: left; }


article {
  position: relative;
  img {
    max-width: 100%;
    margin-bottom: 25px; }
  img.broken {
    display: block;
    width: 100%;
    min-height: 220px;
    position: relative;
    &:before {
      @include pseudo;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #F4F4F4;
      background-image: url('../images/logo-ocbc-sekuritas-gray.svg');
      background-size: 76px;
      background-position: center;
      background-repeat: no-repeat; } }

  p {
    word-break: keep-all;
    /* Non standard for webkit */
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none; }
  a {
    &:visited {
      color: darken($link, 10%); }
    &:hover {
      color: darken($link, 10%);
      text-decoration: underline; } }
  blockquote {
    font-style: italic; }
  li {
    ul, ol {
      margin: 0 1.5em; } }
  ul, ol {
    margin: 0 0 30px;
    padding-left: 25px;
    // &:last-child
 }    //   margin: 0
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      list-style: upper-alpha;
      ol {
        list-style: lower-roman;
        ol {
          list-style: lower-alpha; } } } }
  .btn {
    margin-bottom: 20px;
    &+ .btn:not(.btn-block) {
      margin-left: 10px; } } }



html, body {
  font-size: 15px;
  line-height: 2em;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0px;
  color: $text;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

body {
  background: #FFFFFF;
  position: relative; }
