/* ------------------------------ */
/* FORM */
/* ------------------------------ */

.title {
  margin-bottom: 10px; }

.form-group {
  margin-bottom: 25px; }
label {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px; }

.help-text {
  color: #777777;
  display: block;
  &:last-child {
    margin-top: 10px; } }


.form-control {
  height: 40px;
  border-radius: 4px;
  border: 1px solid $border;
  box-shadow: none;
  font-size: 16px;
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: none !important;
    outline: none !important; } }


.checkbox {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  label {
    margin-bottom: 12px;
    font-weight: normal; }
  &:last-child {
    label {
      margin-bottom: 0; } }
  input[type=checkbox] {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  &> label {
    padding: 0 0 0 30px; }
  span {
    display: inline-block;
    padding: 0;
    &:before {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $border;
      background: #fff;
      transition: all .5s;
      border-radius: 3px;
      transition: background .3s ease-in; }
    &:after {
      content: "\f00c";
      font-family: 'Font Awesome\ 5 Pro';
      position: absolute;
      font-weight: 400;
      top: 3px;
      left: 0;
      width: 18px;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      text-align: center;
      transform: scale(0);
      transition: transform .2s ease-in; } }
  input[type=checkbox]:checked + span {
    &:before {
      background: lighten($link, 15%);
      border-color: $link; }
    &:after {
      transform: scale(1); } } }


.radio {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  label {
    margin-bottom: 12px;
    font-weight: normal; }
  &:last-child {
    label {
      margin-bottom: 0; } }
  input[type=radio] {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  &> label {
    padding: 0 0 0 30px; }
  span {
    display: inline-block;
    padding: 0;
    &:before {
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid $border;
      background: #fff;
      transition: all .5s;
      border-radius: 18px;
      transition: background .3s ease-in; }
    &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 5px;
      width: 8px;
      height: 8px;
      background-color: #FFFFFF;
      border-radius: 18px;
      transform: scale(0);
      transition: transform .3s ease-in; } }
  input[type=radio]:checked + span {
    &:before {
      background: lighten($link, 15%);
      border-color: $link; }
    &:after {
      transform: scale(1); } } }



.file-image,
.file-upload {
  label {
    margin-bottom: 0;
    font-weight: normal;
    &.full {
      width: 100%; } }
  h3 {
    font-size: 1rem; line-height: 1.5em;
    margin: 0 0 5px;
    color: #647282;
    display: block;
    small {
      display: block !important; } }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &+ label {
      border: 1px solid #C4CDD4;  border-radius: 4px; background-color: #FFFFFF;
      line-height: 40px;
      display: inline-block;
      padding: 0 20px;
      font-size: 1rem;
      color: #647282;
      &:hover {
        background-color: darken(#F9F9F9, 5%); }
      &.disable {
        opacity: .5;
        &:hover {}
        background-color: #F9F9F9; } } }
  &.has-error {
    label {
      border-color: #D0021B; }
    .error-msg {
      position: relative;
      display: block;
      margin: 10px 0;
      top: 0;
      left: 0;
      bottom: 0;
      color: $red;
      &:after {
        bottom: calc(50% - 5px);
        left: -8px;
        transform: rotate(90deg); } } }
  .labelbtn {
    background: #fff;
    font-weight: 400;
    &:hover {
      background: #eee; } }
  .img {
    display: none;
    width: 100%;
    height: 210px;
    border: 1px solid #DEE5E7;
    position: relative;
    background: #f6f6f6;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url('../images/icon-camera.svg') no-repeat center rgba(#000,.5);
      opacity: 0;
      transition: all .5s;
      cursor: pointer; }
    span {
      display: block;
      margin: auto; }
    img {
      display: block;
      margin: auto;
      max-width: calc(100% - 10px);
      max-height: 200px; }
    .fa {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $text;
      z-index: 2;
      opacity: 1;
      display: block;
      width: 26px;
      height: 26px;
      line-height: 26px;
      border-radius: 13px;
      text-align: center;
      font-size: 12px;
      background-color: transparent;
      transition: all .3s; }
    &:hover {
      .fa {
        color: #FFFFFF;
        &:hover {
          background-color: #FFFFFF;
          color: $red; } }
      &:after {
        opacity: 1; } }
    &.profile {
      img {
        border-radius: 100%; } }
    &.disabled {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(#DEE5E7,0.5);
        z-index: 2; } } }
  &.with-image {
    .labelbtn {
      display: none; }
    .img {
      align-items: center;
      display: flex;
      display: -webkit-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -webkit-box-pack: center;
      box-align: center; }
    small {
      display: none; } }
  &.uploading {
    .labelbtn {
      background-color: #DEE5E7;
      border: 1px solid #DEE5E7;
      color: #fff;
      position: relative;
      b {
        border-radius: 2px;
        background-color: #4290F8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0; }
      span {
        position: relative; } } }
  &.pic {
    .img {
      margin-top: 10px;
      width: 100px;
      height: 100px;
      border-radius: 100%;
      display: block;
      &:after,&:before {
        border-radius: 100%; }
      .image {
        background-size: cover;
        background-position: center;
        background-color: #F2EEE4;
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        background-image: url("../images/user.jpg"); }
      .fa {
        top: 0;
        right: -30px;
        width: 30px;
        line-height: 30px;
        border: 1px solid $border;
        border-radius: 100%;
        color: $primary;
        text-align: center; } }
    .btn,.fa {
      display: none; }
    &.with-image {
      .btn,.fa {
        display: inline-block; } } } }
.file-upload {
  label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0; }
  .labelbtn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0; }
  .remove {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    display: none;
    margin-left: 15px;
    &:hover {
      opacity: .5; } }
  &.with-doc {
    .remove {
      display: inline-block; } } }




.select-control {
  font-size: 15px;
  background-color: #FFF;
  border: 1px solid #E2E2E2;
  padding: 0 12px;
  padding-left: 12px !important;
  font-weight: normal;
  outline: none !important;
  color: $text;
  line-height: 38px;
  .caret {
    width: 10px;
    height: 14px;
    border: 0;
    top: calc(50% - 5px) !important;
    right: 10px !important;
    &:before {
      // content: "\f0dc"
      content: "\f0d7";
      font-family: FontAwesome;
      font-size: 12px;
      line-height: 14px;
      color: $text;
      position: absolute;
      top: 0;
      left: 0; } }
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: $text; }
  &.disabled.dropdown-toggle {
    background-color: #F9FAFB; } }



.multiplier {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 25px;
  .form-group:last-child {
    margin-bottom: 0; }
  .item {
    position: relative; }
  .btn-del {
    border: 0;
    background: none;
    position: absolute;
    right: 5px;
    top: 15px;
    display: none;
    &:focus,
    &:active,
    &:active:focus {
      outline: none !important; }
    &:hover {
      color: $red; } }
  .btn-add {
    border: 1px solid $border;
    background-color: #FFFFFF;
    border-radius: 4px;
    line-height: 30px;
    padding: 0 12px;
    position: relative;
    z-index: 3;
    transition: all .3s;
    .fal,
    .fas,
    .far {
      margin: 0 10px;
      &:last-child {
        margin-right: 0; }
      &:first-child {
        margin-left: 0; } }
    &:focus,
    &:active,
    &:active:focus {
      outline: none !important; }
    &:hover {
      background-color: lighten($border, 5%); } }
  .block-add {
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $border;
      left: 0;
      top: 14px;
      z-index: 1; } }
  &.multiplied {
    .item {
      padding: 15px;
      padding-right: 35px;
      border: 1px solid $border;
      border-bottom: 0;
      background-color: #FFFFFF;
      &:last-child {
        border-bottom: 1px solid $border; } }
    .btn-add {
      line-height: 40px;
      width: 100%;
      border-radius: 0; }
    .btn-del {
      display: block; } } }


.panel-list {
  width: 100%;
  min-height: 60px;
  background-color: #FFFFFF;
  border: 1px solid $border;
  padding: 15px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  ul.box-item {
    min-height: 35px;
    position: relative;
    &:before {
      content: attr(data-blank);
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 35px;
      line-height: 35px;
      font-size: 12px;
      text-align: center;
      color: $text-light;
      background-color: #F9F9F9; }
    &.blank:before {
      display: block; }
    li {
      display: block;
      margin-bottom: 10px;
      border: 1px solid $border;
      border-radius: 2px;
      background: #FFFFFF;
      position: relative;
      .btn-del {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 12px;
        line-height: 21px;
        cursor: pointer;
        &:hover {
          color: $red; } } }

    &> li {
      span {
        background: #FFFFFF;
        display: block;
        font-size: 14px;
        line-height: 21px;
        padding: 7px 15px;
        &:hover {
          cursor: move; } } }

    ul {
      margin: 0 10px;
      li {
        border-radius: 2px;
        span {
          background-color: #F9F9F9; }
        &:first-child {
          margin-top: 5px; }
        ul {
          .menu-highlight {
            border-color: $red !important; } } } } }

  .menu-highlight {
    border: 1px dashed $border !important;
    background: #f5f5f5;
    margin-bottom: 10px;
    border-radius: 2px; } }
