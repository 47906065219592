/* ------------------------------ */
/* HEADER */
/* ------------------------------ */

header {
  background: #FFFFFF;
  padding: 15px 0;
  font-family: "Proxima Nova", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  transition: padding 0.5s;
  .logo {
    float: left;
    &> a {
      display: block;
      img {
        max-height: 50px;
        height: 50px;
        width: auto;
        transition: all .5s; } } }

  .main-menu {
    float: right;
    &> ul {
      list-style: none;
      font-size: 0;
      line-height: 0;
      margin-left: -30px;
      margin-right: -30px;
      &> li {
        display: inline-block;
        line-height: 50px;
        padding: 13px 30px 13px;
        transition: padding 0.5s;
        // &:last-child
        //   padding-right: 0
        // &:first-child
        //   padding-left: 0
        &.active,
        &.current-menu-item {
          a {
            font-weight: bold;
            color: $primary; } }
        &> a {
          font-size: 15px;
          line-height: 24px;
          display: block;
          color: $text;
          transition: color .5s;
          position: relative;
          &:hover {
            color: $primary; }
          .icon-back {
            margin-right: 5px;
            font-size: .8em; } } } } }

  &.sticky {
    padding: 10px 0;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    .logo {
      &> a img {
        max-height: 30px;
        height: 30px; } }
    .main-menu {
      &> ul > li {
        padding-top: 3px;
        padding-bottom: 3px; } } }

  @include mobile {
    .logo {
      float: none;
      text-align: center;
      margin-bottom: 10px; }

    .main-menu {
      float: none;
      &> ul {
        text-align: center;
        &> li {
          padding: 10px 15px 0; } } } } }
