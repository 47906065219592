/* ------------------------------ */
/* CONTENT */
/* ------------------------------ */


.masthead {
  position: relative;
  // .item
  //   width: 100%
  //   .bg-img
  //     width: 100%
  //     height: 100vh
  //     background-position: center
  //     background-size: cover
  //     background-repeat: no-repeat

  .next {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    width: 100%;
    z-index: 2;
    span {
      color: #FFFFFF;
      display: block;
      line-height: 24px;
      margin-bottom: 20px; }
    .arrow img {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite; }
    @include responsive(1200px) {
      bottom: 80px; }
    @include mobile {
      bottom: 70px; } }

  &.masthead-img {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border;
    position: relative;
    z-index: 1;
    transform: translateY(0);
    .bg-img {
      @include abspos(0,0,0,0);
      z-index: 1;
      background-color: #000000;
      .item {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        .img {
          @include abspos(0,0,0,0);
          height: calc(100vh + 25px);
          background-size: cover;
          background-position: center 0;
          background-repeat: no-repeat;
          animation: bgUp 6s linear infinite; } } }
    .container {
      z-index: 2; } } }


.thumb {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;
  .img {
    width: 100%;
    padding-top: 55%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: scale(1);
    transition: transform 1s;
    position: relative;
    &:after {
      @include pseudo;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $primary;
      opacity: 0;
      transition: opacity .5s; } }
  .icon {
    width: 100px;
    height: 100px;
    margin: 0 auto 30px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(1);
    transition: transform 1s; }

  .title-section {
    max-width: 200px;
    margin: 0 auto; }



  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 21px;
      line-height: 30px;
      color: #FFFFFF;
      font-weight: bold;
      margin-bottom: 0; } }
  &:hover {
    @include responsive(0,1200px) {
      cursor: pointer;
      .img {
        transform: scale(1.125); }
      .img:after {
        opacity: .7; } } } }



.post {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .img-box {
    width: 100%;
    padding-top: 100%;
    position: relative;
    .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        max-width: 100%; }
      .btn {
        position: absolute;
        left: calc(50% - 40px);
        bottom: 15px;
        min-width: 80px; } } }
  .content-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: all .6s;
    &:before {
      @include abspos(0,0,0,0);
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: #FF4757;
      opacity: 0;
      z-index: 1;
      transition: all 1s; }
    .content {
      z-index: 2;
      position: relative;
      padding: 20px;
      color: #FFFFFF;
      h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 0;
        height: 50px;
        max-height: 72px;
        overflow: hidden; }
      p {
        font-size: 15px;
        line-height: 24px; }
      .box-text {
        max-height: 100px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px; }
        &::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 10px; }
        &::-webkit-scrollbar-thumb {
          background: rgba(255,255,255,.5);
          border-radius: 5px; } } }
    .exit:last-child {
      color: #FFFFFF;
      position: absolute;
      z-index: 2;
      display: block;
      bottom: 15px;
      left: 20px;
      font-size: 13px;
      line-height: 15px; }
    a.link {
      z-index: 2;
      position: absolute;
      display: block;
      bottom: 20px;
      left: 20px;
      color: #FFFFFF;
      &:after {
        content: "\f178";
        font-family: Font Awesome\ 5 Pro;
        display: inline-block;
        margin-left: 10px;
        font-weight: 300;
        font-size: 16px;
        opacity: 0;
        position: relative;
        transform: translateX(-30px);
        transition: all .3s; }
      &:hover {
        opacity: .8;
        &:after {
          opacity: 1;
          transform: translateX(0); } }
      &:not(:last-child) {
        bottom: 45px; } } }
  &:hover {
    .img {
      .btn {
        display: none; } }
    .content-box {
      opacity: 1;
      &:before {
        opacity: .9; } } }
  @include responsive(1200px) {
    .img-box .img .btn {
      display: none; }
    .content-box {
      position: relative;
      opacity: 1;
      &:before {
        display: none; }
      .content {
        color: $text;
        h3 {
          margin-bottom: 15px;
          height: auto; }
        .box-text {
          max-height: initial; } }
      a.link {
        position: relative;
        top: auto;
        bottom: auto !important;
        left: auto;
        color: $primary;
        padding: 0 20px; }
      .exit:last-child {
        display: block;
        color: $primary;
        position: relative;
        bottom: auto !important; } }
    &:hover {
      .content-box:before {
        display: none; } } } }


.teams {
  width: 100%;
  position: relative;
  @include clearfix();
  .team {
    float: left;
    width: 25%;
    .box {
      position: relative;
      overflow: hidden;
      padding: 0.5px;
      .img {
        width: 100%;
        padding-top: 140%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%); } }
      .content {
        padding: 30px;
        position: absolute;
        top: calc(100% - 156px);
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .5s;
        h3 {
          color: #FFFFFF;
          font-size: 18px;
          line-height: 36px;
          font-weight: 600;
          margin-bottom: 0; }
        p {
          color: #FFFFFF;
          font-size: 15px;
          line-height: 30px;
          margin-bottom: 30px; }
        .detail {
          opacity: 0;
          position: absolute;
          top: 155px;
          left: 30px;
          height: calc(100% - 185px);
          width: calc(100% - 60px;
          overflow: auto;
          transition: opacity .3s;
          &::-webkit-scrollbar {
            width: 5px; }
          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px; }
          &::-webkit-scrollbar-thumb {
            background: rgba(255,255,255,.5);
            border-radius: 5px; }

          p {
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 20px; } }

        @include responsive(0, 1600px) {
          padding: 60px;
          top: calc(100% - 216px);
          p {
            max-width: 300px; }
          .detail {
            top: 185px;
            left: 60px;
            height: calc(100% - 245px);
            width: calc(100% - 120px); } } }

      &:hover {
        .content {
          top: 0;
          background-color: rgba(255,71,87,0.9);
          .detail {
            opacity: 1; } } } } }
  @include tablet {
    .team {
      width: 50%; } }
  @include mobile {
    .team {
      width: 100%; } } }

// ACCORDION
.accordion {
  overflow: hidden;
  border-radius: 6px;
  .panel {
    padding: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    border: 0;
    min-height: 1px;
    border-bottom: 1px solid rgba(87,96,111,.5);
    &:last-child {
      border-bottom: 1px solid transparent; }
    .panel-heading {
      position: relative;
      display: block;
      width: 100%;
      padding: 20px 0;
      border-radius: 0;
      border: 0;
      color: $text;
      font-size: 18px;
      line-height: 36px;
      background-color: transparent;
      text-align: left;
      padding-right: 40px;
      &:focus {
        outline: none !important; }
      &:after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background-image: url(../images/icon-arrow-right.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 28px;
        right: 0;
        transform: rotate(0);
        transition: transform .5s ease; }
      .title {
        margin: 0;
        font-size: 18px;
        line-height: 36px;
        font-weight: normal;
        position: relative;
        &.has-icon {
          padding-left: 100px; }
        .icon {
          width: 60px;
          height: 60px;
          line-height: 60px;
          // background-color: #e2e2e2
          display: block;
          position: absolute;
          left: 0;
          top: -12px;
          img {
            max-width: 100%;
            max-height: 100%; } }
        small {
          font-size: 18px;
          line-height: 36px;
          font-weight: normal;
          position: absolute;
          top: 0;
          right: 10px;
          opacity: .5; } }
      &.collapsed:after {
        transform: rotate(90deg); }
      @include mobile {
        .title {
          &.has-icon {
            padding-left: 60px; }
          .icon {
            width: 40px;
            height: 40px; }
          small {
            position: relative;
            right: auto;
            top: auto;
            margin-top: 0;
            display: block; } } } }
    .panel-body {
      padding: 0 0 30px;
      article {
        h4 {
          font-weight: 600;
          margin-bottom: 10px; }
        a {
          color: $text;
          transition: color .5s;
          &:hover {
            color: $primary; } }
        :last-child {
          margin-bottom: 0; } } } } }


.maps {
  width: 100%;
  padding-top: 35%;
  background: url(../images/indonesia-map.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  .marker {
    width: 16px;
    height: 44px;
    background: url(../images/icon-map-pin.svg);
    position: absolute;
    display: block;
    z-index: 3;
    overflow: visible;
    transition: all .3s;
    &:before,
    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      left: -2px;
      top: -2px;
      z-index: 1;
      // box-shadow: 0 0 0 rgba(255,255,255,1)
      box-shadow: 0 0 0 red;
      animation: pulse 2.5s infinite; }
    &:after {
      animation: bigPulse 2.5s infinite; }
    &:hover {
      // background-color: red
      color: #FFF; }
    .balloon {
      background: #2F3542;
      min-width: 200px;
      border-radius: 6px;
      color: #FFFFFF;
      position: absolute;
      bottom: 100%;
      z-index: 10;
      left: -90px;
      // height: 0
      padding: 10px;
      // max-height: 0
      visibility: hidden;
      overflow: hidden;
      opacity: 0;
      transition: all .5s, height 2s;
      transition-timing-function: cubic-bezier(1, 0.5, 0.5, 0.1);
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #2F3542 transparent transparent transparent;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        margin: 0 auto; }

      .location {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0; }
        p {
          margin-bottom: 0; }
        span {
          opacity: .8;
          display: block;
          &.product {
            opacity: .5; } } } }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 8px -5px rgba(0, 0, 0, .5);
      cursor: pointer;
      z-index: 11;
      .balloon {
        overflow: visible;
        opacity: 1;
        // height: auto
        // max-height: 99999px
        bottom: calc(100% + 15px);
        visibility: visible; } } }

  @include mobile {
    .marker {
      transform: scale(0.5);
      transform-origin: bottom left; } } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255,71,87, 0.4); }
  90% {
    box-shadow: 0 0 0 15px rgba(255,71,87, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255,71,87, 0); } }
@keyframes bigPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255,71,87, 0.2); }
  90% {
    box-shadow: 0 0 0 30px rgba(255,71,87, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255,71,87, 0); } }

.page-not-found {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 190px;
    line-height: 1em;
    color: $primary;
    font-weight: 300; }
  p {
    font-size: 33px;
    line-height: 1.5em; }
  .fal {
    margin-right: 5px;
    font-size: .8em; }
  @include mobile {
    h1 {
      font-size: 72px; }
    p {
      font-size: 21px; } } }


.row.row-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    .col-sm-6 {
        width: calc(50% - 1px); } }
  @media (min-width: 992px) {
    .col-md-3 {
      width: calc(25% - 1px); } }
  @media (max-width: 1200px) and (min-width: 798px) {
    .col-md-3 {
      width: calc(33.33% - 1px); } } }
